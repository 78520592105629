.client-card{
    background-color: lightgrey;
    color: black;
    transition: transform 0.5s ease-in-out;
    padding: 36px !important;
    text-align: left!important;
    margin: 10px;
    min-height: 40vh;
}

.client-name{
    font-size: 20px;
}

.client-card p{
    margin-bottom: 0;
}

.client-card:hover{
    background-color: var(--app-red);
    color: var(--text-primary);
    transform: scale(1.05);
    box-shadow: 0 10px 20px var(--text-grey), 0 4px 8px var(--text-grey);
}