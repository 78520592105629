.exp{
    background-color: lightgrey;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: black;
    transition: transform 0.5s ease-in-out;
    padding: 36px !important;
    margin: 10px;
}

.exp:hover{
    cursor: pointer;
    background-color: var(--app-red);
    color: var(--text-primary);
    transform: scale(1.01);
    box-shadow: 0 10px 20px var(--text-grey), 0 4px 8px var(--text-grey);
}
.exp-inner{
    display: flex;
}

.exp-post{
    display: flex;
    font-weight: 700;
    font-size: 18px;
    /* margin: auto; */
}

@media only screen and (max-width:767px){
    .exp-post{
        display: flex;
        flex-direction: column;
    }
    .exp-inner{
        display: flex;
        flex-direction: column;
    }
    .exp-icon{
        align-self: center;
        margin-bottom: 2vh;
    }
}