.addDataButton {
  padding: 5px 15px;
  margin: 15px 0;
  border: none;
  background-color: white;
  color: teal;
  border-radius: 5px;
  border: 2px solid teal;
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  cursor: pointer;
}
.addButtonDataDisabled {
  padding: 5px 15px;
  margin: 15px 0;
  border: none;
  background-color: white;
  color: teal;
  border-radius: 5px;
  border: 2px solid teal;
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  opacity: 0.5;
}
.addDataButton:hover {
  background-color: teal;
  color: white;
}
.addDataInput {
  border: 2px solid teal !important;
  margin: 15px 0px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

