
.blog-container {
  max-width: 80vw;
  margin: auto;
}
.blog-card h2,
.blog-card p {
  text-align: center;
}
.blog-text {
  width: 50vw;
  font-size: 20px;
  margin: 0 auto;
}
.blog-text-p {
  font-weight: 550;
}

.blog-vec-left {
  /* width: 14vw; */
  transform: rotate(180deg);
  opacity: 0.8;
}
.blog-vec-right {
  opacity: 0.8;
}

.blog-highlight {
  display: flex;
  flex-direction: column;
  margin: 7vh 0 3vh 0;
  background-color: var(--text-primary);
  box-shadow: 0 2px 5px #d1d1d1, 0 0 2px #d1d1d8;
  transition: box-shadow 0.5s ease-in-out;
  text-align: center;
  position: relative;
  background: black;
  overflow: hidden;
}

.blog-highlight:hover {
  /* box-shadow: 0 6px 10px #d1d1d1, 0 0 6px #d1d1d8; */
  cursor: pointer;
}

.blog-highlight-overlay {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  color: lightgrey;
}

.blog-highlight-overlay p {
  margin-left: 2px;
}
.bh-share{
    position: absolute;
  top: 20px;
  right: 20px;
  color: lightgrey;
}


.bh-img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  opacity: 0.5;
  transition: transform 0.7s ease-in-out;
}

.bh-img:hover {
  transform: scale(1.1);
}

.bh-heading {
  font-size: 32px;
  color: var(--text-primary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bh-text-body {
  padding: 10px;
}

.insta-images{
  width: 20vw;
  height: 20vw;
  object-fit: cover;
}
@media only screen and (max-width: 1024px) {
  .blog-text {
    width: 55vw;
    margin-left: 5px !important;
  }
  .blog-vec-right {
    /* display: none; */
  }
  .blog-vec-left {
    /* width: 20vw;
        transform: rotate(0deg); */
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .blog-text {
    width: 80vw;
  }
  .blog-vec-left {
    display: none;
  }
  .blog-vec-right {
    display: none;
  }
  .bh-heading {
    font-size: 24px;
  }
  .blog-highlight-overlay {
    top: 10px;
    left: 10px;
  }
  .bh-share{
      top: 10px;
      right: 10px;
  }
}
@media only screen and (max-width: 767px)
{
  .insta-images{
    width: 80vw;
    height: 80vw;
  }
}
