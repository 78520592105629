.social-media-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: fixed;
  top: 30vh;
  margin-left: 5vw;
  z-index: 5;
  background-color: var(--text-primary);
}
.social-media-icons {
  font-size: 24px !important;
  color: var(--text-grey);
  transition: color 0.5s ease !important;
  margin: 15px 0;
}
.social-media-icons:hover {
  color: var(--app-red);
  cursor: pointer;
  transform: scale(1.2);
}
@media only screen and (max-width: 767px) {
  .social-media-container {
    display: none !important;
  }
}
