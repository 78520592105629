.blog-post-container{
    width: 60%!important;
}

.blog-post-date{
    color: var(--text-grey);
}
.blog-post-image{
    box-shadow: 10px 5px 5px var(--text-grey);
}
.blog-post-sub{
    display: flex;
    justify-content: space-between;
}
.blog-post-icons{
    font-size: 24px;
}
.bp-icon{
    color:var(--text-grey);
    transition: color 0.5s ease !important;
}
.bp-icon:hover{
    color: var(--app-red);
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .blog-post-container{
        width: 80%!important;
    }
  }
  