.btn-flip {
  opacity: 1;
  outline: 0;
  color: #fff;
  line-height: 40px;
  position: relative;
  text-align: center;
  /* margin: 5px 5px; */
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  font-family: "Open Sans";
  text-transform: capitalize;
}
.btn-flip:hover::after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
  cursor: pointer;
}
.btn-flip:hover::before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
  cursor: pointer;
}
.btn-flip::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: var(--text-primary);
  display: block;
  transition: 0.5s;
  position: absolute;
  background: var(--app-red);
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
  /* border-radius: 8px; */
}

.btn-flip::before {
  top: 0;
  left: 0;
  opacity: 1;
  color: black;
  display: block;
  padding: 0 30px;
  line-height: 40px;
  transition: 0.5s;
  position: relative;
  background: lightgrey;
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
  /* border-radius: 8px; */
}

.nav-tab-active::before {
  background: var(--app-red);
  color: var(--text-primary);
}
.no-btn-flip {
  color: black;
  font-size: 20px;
  font-weight: 400;
  transition: background-color 0.5s ease;
  /* border-radius: 10px; */
  padding: 10px;
}
.no-btn-flip:hover {
  cursor: pointer;
  background-color: var(--app-red);
  color: var(--text-primary);
}

.no-btn-flip-selected {
  cursor: pointer;
  background-color: var(--app-red);
  color: var(--text-primary);
}

@media only screen and (max-width: 767px) {
  .categories p {
    font-size: 13px;
  }
  .no-btn-flip {
    font-size: 16px !important;
  }
  .btn-flip::before {
    padding: 0 10px;
  }
}
