:root {
    --black: #000000;
    --white: #ffffff;
  }
input:focus, textarea:focus, select:focus{
    outline: none;
}
.login-block{
    display: block;
    margin: 0 10px 10px 0;
    font-weight: 700;
}
.input-email,.input-password{
    display: block;
    margin: 0 0px 10px 0;
    width: 100%;
    padding: 7px 0;
    padding-left: 7px;
    text-decoration: none;
    box-shadow: none;
    border: none;
    font-size: 14px;
}
.login-error{
    display: block;
    font-weight: 700;
    color: var(--red);
    text-align: center;
}
.login-success{
    display: block;
    font-weight: 700;
    color: green;
    text-align: center;
}
.login-button{
    display: block;
    margin: 15px auto;
    padding: 7px 15px ;
    border: none;
    color: white;
    background-color: #000000;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    max-width: 350px;
    text-transform: uppercase;
    box-shadow: 2px 2px 5px 1px var(--dark_black);
}
.login-container{
    height: 85vh;
    margin: auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}