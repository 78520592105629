.big-img {
  width: 100%;
}
.home-image-container{
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.home-image-small{
  width: 20vw;
  height: 20vw !important;
  object-fit: cover;
}
.home-image-big{
  width: 40vw;
  height: 40vw !important;
  object-fit: cover;
}

@media only screen and (max-width:767px){
  .home-image-small{
    width: 40vw;
    height: 40vw !important;
    object-fit: cover;
  }
  .home-image-big{
    width: 80vw;
    height: 80vw !important;
    object-fit: cover;
  }
}

@media only screen and (max-width:575px){
  .home-image-small{
    width: 80vw;
    height: 80vw !important;
    object-fit: cover;
  }
  .home-image-big{
    width: 80vw;
    height: 80vw !important;
    object-fit: cover;
  }
}