.footer {
  display: none;
}

@media only screen and (max-width: 767px) {
  .footer {
    display: flex;
    flex-direction: column;
    position: sticky !important;
    bottom: 0;
    background-color: var(--text-primary);
    z-index: 5;
  }
  .f-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2vh 20vw;
  }
  .footer-icon {
    font-size: 24px !important;
    color: var(--text-grey);
    transition: color 0.5s ease !important;
  }
  .footer-icon:hover {
    color: var(--app-red);
    cursor: pointer;
    transform: scale(1.2);
  }
}
