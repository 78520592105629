:root {
  --text-primary: #fffffe;
  --text-grey: #94a1b2;
  --app-red: #b61d07;
  --app-yellow: #f6a928;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection { /* Code for Firefox */
  color: var(--text-primary);
  background: var(--app-red);
}

::selection {
  color: var(--text-primary);
  background: var(--app-red);
}

/* .row > * {
  padding: 0 !important;
} */

.row {
  --bs-gutter-x: 0 !important;
}

.custom-container {
  width: 80vw;
  margin: auto;
  box-sizing: border-box;
  position: relative;
}

.image-padding {
  padding: 5px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--app-red);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--app-red);
}

@media only screen and (max-width:767px){
  /* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--app-red);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--app-red);
}
}

.pageFade{
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}