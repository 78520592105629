.subtitle{
    padding: 15px 0;
    font-weight: 500;
}
.work-dialog-conatiner{
    height: 100vh;
    overflow-y: scroll;
}

.dialog-header{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dialog-cancel{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: lightgrey;
    transition: color 0.2s ease-in-out;
}

.dialog-cancel:hover{
    color: var(--app-red);
}

.work-images img{
    width: 100%;
    height: 250px !important;
    object-fit: cover;
}
.work-page-half{
    height: 30vh;
    width: 100%;
    object-fit: cover;
}
.work-page-big{
    height: 50vh;
    width: 100%;
    object-fit: cover;
}
.work-page-small{
    height: 31vh;
    width: 100%;
    object-fit: cover;
}
@media only screen and (min-width: 767px) {
    .work-details{
        padding-right: 20px !important ;
        height: 50vh;
        margin-left: auto !important ;
        overflow-y: scroll;
    }
  }