.img_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image_hover {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: #b61d078a; */
}

.home-card {
  display: flex;
  justify-content: flex-end;
  width: 80vw;
  margin: auto;
  padding-bottom: 2vh;
  margin-bottom: 5vh;
  margin-top: 1vh;
  align-items: center;
   background-color: var(--text-primary);
  box-shadow: 0 2px 5px #d1d1d1, 0 0 2px #d1d1d8;
  transition: box-shadow 0.5s ease-in-out;

}
.home-card:hover{
  box-shadow: 0 6px 10px #d1d1d1, 0 0 6px #d1d1d8;
}

.home-avatar {
  width: 18vw;
  /* border: 1px solid black; */
  /* box-shadow: 0 2px 5px #d1d1d1, 0 0 2px #d1d1d8; */
  margin-right: 1vw;
}

.home-card-content{
display: flex;
flex-direction: column;
}

.home-arrow{
  /* transform: rotate(270deg); */
  transform: rotate(270deg);
  margin-top: 5vh;
  margin: auto;
}

.home-desc {
  max-width: 40vw;
  font-weight: 300;
  font-size: 26px;
  /* text-align: right; */
}
.home-desc strong{
  font-size: 44px;

}

.img_container:hover .overlay {
  opacity: 1;
}

.image_desc {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


@media only screen and (max-width: 1024px) {
  .home-card {
    display: flex;
    margin: auto;
    padding-bottom: 3vh;
    align-items: center;
    margin-bottom: 5vh;
    margin-top: 1vh;
    width: 78vw;
  }
  .home-avatar {
    width: 20vw;
    height: auto;
    margin-bottom: 2vh;
  }
  
  .home-desc {
    display: table-row-group;
    width: 90vw;
    padding-bottom: 3vh;
    font-size: 20px !important;
  }
  .home-desc strong{
    font-size: 24px !important;
  
  }
  
}

@media only screen and (max-width: 767px) {
  .home-card {
    display: flex;
    flex-direction: column;
    width: 83vw;
    margin-top: 1vh;
  }
  .home-card h3{
    order: 2;
  }
  .home-avatar {
    display: table-header-group;
    width: calc(100% - 60px);
    margin-bottom: 2vh;
  }
  .home-desc {
    display: table-row-group;
    max-width: 75vw;
    margin: auto;
    margin-top: 3vh;
    padding-bottom: 3vh;
    font-size: 18px !important;
  }
  .home-desc strong{
    font-size: 22px !important;
  }
  .home-card-vec{
    display: none;
  }
  
}


/* Home Category */
.home-category{
display: flex;
}

.home-card-vec{
  width: 18vw;
  padding-right: 2vw;
  opacity: 0.8;
}