.about h2 {
  text-align: center;
  margin-bottom: 5vh;
}

.about-content {
  display: flex;
}

.about-content p {
  font-size: 20px;
}

.skills {
  margin-top: 5vh !important;
}

.about-banner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 30%;
  margin-left: auto;
  margin-right: 10vw;
}
/* Experience Section */

.am-experience {
  text-align: center;
  margin-top: 5vh;
}

.ame-title {
  font-size: 24px;
  color: rgb(139, 28, 28);
  margin: "20px 0";
}

/* Client Section */

.feedback-section {
  padding-top: 10vh;
  text-align: center;
  position: relative;
}
.am-client {
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
}
.amc-title {
  font-size: 24px;
  color: rgb(139, 28, 28);
}

.button {
  margin-top: 4vh;
  text-align: center;
}

.contact-button {
  padding: 20px 30px;
  border: 1px solid var(--app-red);
  background-color: white;
  transition: background-color 0.5s ease;
  color: var(--app-red);
  border-radius: 5px;
}

.contact-button:hover {
  background-color: var(--app-red);
  color: var(--text-primary);
}

.about-feedback {
  text-align: left;
}
.about-client-name {
  font-size: 20px;
  margin-bottom: 0px;
}
.about-client-desg {
  font-size: 16px;
}

/* SVG Icons for skills section MUI
Category
Brush
BorderColor*/

.about-banner {
  background-image: url("../../assets/about.jpg");
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  object-fit: contain;
  padding-top: 25vh;
  color: black;
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .about-banner-text {
    max-width: 50%;
  }
}


@media only screen and (max-width: 767px) {
  .about-banner-text {
    max-width: 80%;
  }
  .about-banner {
    background-position: right;
  }
}
