.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1vh 2.5vw 0 0;
  position: sticky !important;
  top: 0px;
  z-index: 50;
  background-color: white;
  width: 88.5vw;
  margin-left: 4vw;
  max-width: calc(100vw - 50px);
  flex-wrap: nowrap !important;
}

.navbar-transparent {
  position: fixed !important;
  background-color: transparent;
}

.navbar-transparent.active {
  background-color: white;
  /* opacity: 0.5; */
}

.navbar img {
  width: 90px;
  height: auto;
}

.navbar__content {
  display: flex;
  font-size: 20px;
}
.navbar__content a {
  margin: 5px;
}

@media only screen and (max-width: 767px) {
  .navbar {
    width: 90vw;
    padding: 1vh 0;
  }
  .navbar__content {
    font-size: 16px;
  }
}
