.skills-card{
    text-align: center;
    margin: 5px !important;
}
.card-icon{
    min-width: 1px;
    height: 120px;
    background-color: red;
}

.sc-title{
    font-size: 20px;
}
.sc-desc{
    text-align: left;
    font-size: 16px;
}