.addDataButton {
  padding: 5px 15px;
  margin: 15px 0;
  border: none;
  background-color: white;
  color: teal;
  border-radius: 5px;
  border: 2px solid teal;
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  cursor: pointer;
}
.addButtonDataDisabled {
  padding: 5px 15px;
  margin: 15px 0;
  border: none;
  background-color: white;
  color: teal;
  border-radius: 5px;
  border: 2px solid teal;
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  opacity: 0.5;
}
.addDataButton:hover {
  background-color: teal;
  color: white;
}
.buttonSelected {
  background-color: teal;
  color: white;
  outline: none;
}
.addDataInput {
  border: 2px solid teal !important;
  margin-top: 15px;
}
.componentsBaseContainer {
  min-height: 100vh;
  position: relative;
}
.componentBase__left {
  z-index: 50000;
}
.menulist {
  background-color: teal;
  height: 100vh;
  z-index: 50000;
  position: fixed;
  padding-top: 20px;
  left: 0;
  top:0;
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: center;
  color: white;
  transition: left 0.3s ease-in-out;
  border-radius: 0px 15px 15px 0px;
}
.list{
  display: flex;
  flex-direction: column;
}

.edit-blog-post{
  /* d-flex align-items-center justify-content-between */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid teal !important;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.edit-bp-content{
display: flex;
flex-direction: column;
}

.edit-bp-content p{
  margin-bottom: 0px;
}

.edit-bin:hover{
color: red;
cursor: pointer;
}

.ad-hp{
  display: flex;
  flex-direction: column;
}