.icon-container {
  text-align: center;
  position: fixed;
  bottom: 10vh;
  right: 2vw;
  z-index: 2;
}
.icon-container-default {
  color: lightgrey;
  transition: color 1s ease-in-out !important;
}
.icon-container-default:hover {
  color: var(--app-red);
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.arrow-down {
  color: var(--app-red);
  transform: rotate(90deg);
  cursor: pointer;
}
.arrow-up {
  color: var(--app-red);
  transform: rotate(-90deg);
  cursor: pointer;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
